body {
	overflow-y: hidden !important;
}

/*Фиксация заголовков!!!!*/
.ui.table thead tr:first-child > th {
     position: sticky !important;
     top: 0;
     z-index: 50;
}

.ui.table tbody>tr:last-child>td { 
    border-bottom: 1px solid rgba(34,36,38,.1);
}

/* disabled поля по ярче*/
.ui.form .field.disabled :disabled {
  opacity: 1;
}

.div-tab {
	height: 100%;
    overflow-y: auto;
    border: 1px solid #eaeaea;
    border-radius: 5px;
}

tr.selected-row {
    font-weight: bold;
}

.selected-row td:first-child:after {
	border-left: 3px solid orange;
}

td .td-text {
    display: initial;
    margin-left: 5px;
    display: flex;
}

table{
    border: none !important;
}

div .model.list {
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: 0.28571429rem;
}